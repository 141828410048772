<template>
  <v-img
    style="background-color: #234e79; background-image: linear-gradient(110deg, #234e79 0%, #9895e2 100%);"
  >
    <base-section
      id="lavvira-features"
    >
      <v-responsive
        class="mx-auto"
        max-width="1200"
      >
        <v-container fluid>
          <v-card
            class="mx-auto"
            outlined
            color="transparent"
          >
            <v-list
              color="transparent"
              class="pb-4"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                >
                  <div
                    style="font-size: 24px;"
                    class="font-weight-bold white--text mb-6 ml-5"
                  >
                    Core features
                  </div>
                  <v-list-item
                    v-for="(item, index) in features"
                    :key="index"
                    two-line
                    :class="index % 2 == 0 ? 'feature-item' : 'feature-item-second'"
                  >
                    <v-icon
                      color="white"
                      large
                      class="tilt"
                    >
                      {{ item.icon }}
                    </v-icon>
                    <v-list-item-content>
                      <div
                        class="ml-4 white--text font-weight-bold"
                        style="font-size: 20px;"
                      >
                        {{ item.title }}
                      </div>
                      <v-list-item-subtitle
                        class="ml-4 white--text"
                        style="white-space: normal; font-size: 16px;"
                      >
                        {{ item.subtitle }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                >
                  <div
                    style="font-size: 24px;"
                    class="font-weight-bold white--text mb-6 ml-5"
                  >
                    Advanced features
                  </div>
                  <v-list-item
                    v-for="(item, index) in advancedFeatures"
                    :key="index"
                    two-line
                    :class="index % 2 == 0 ? 'feature-item' : 'feature-item-second'"
                  >
                    <v-icon
                      color="white"
                      large
                      class="tilt"
                    >
                      {{ item.icon }}
                    </v-icon>
                    <v-list-item-content>
                      <div
                        class="ml-4 white--text font-weight-bold"
                        style="font-size: 20px;"
                      >
                        {{ item.title }}
                      </div>
                      <v-list-item-subtitle
                        class="ml-4 white--text"
                        style="white-space: normal; font-size: 16px;"
                      >
                        {{ item.subtitle }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-list>
          </v-card>
        </v-container>
      </v-responsive>
    </base-section>
  </v-img>
</template>

<script>
  export default {
    name: 'LavviraFeatures',

    data: () => ({
      items: [
        {
          text: 'Home',
          icon: 'mdi-home-outline',
        },
        {
          text: 'Features',
          icon: 'mdi-feature-search-outline',
        },
        {
          text: 'Book a demo',
          icon: 'mdi-presentation',
        },
        {
          text: 'Company',
          icon: 'mdi-domain',
        },
        {
          text: 'Team',
          icon: 'mdi-account-supervisor-circle',
        },
        {
          text: 'Pricing',
          icon: 'mdi-label-outline',
        },
      ],
      features: [
        {
          title: 'Smart template builder',
          subtitle: 'Turn any photo or scan into a template with relevant data placeholders with just one click.',
          icon: 'mdi-text-box-outline',
        },
        {
          title: 'Data extraction',
          subtitle: 'Get relevant data from any document with the help of AI.',
          icon: 'mdi-database-cog-outline',
        },
        {
          title: 'Smart data filling',
          subtitle: 'Seamlessly enter the already automatically extracted data into the documents you create using the powerful lavvira editor. Also write comments, create tables, lists, change your font and its size, underline, highlight with colors and much much more...',
          icon: 'mdi-file-document-edit-outline',
        },
        {
          title: 'Integrated questionnaire',
          subtitle: 'The personal details of your customers, as well as the specific information you require from them, are available directly in the documents you create.',
          icon: 'mdi-message-question-outline',
        },
        {
          title: 'Case management',
          subtitle: 'Manage and work on your cases from wherever you want.',
          icon: 'mdi-briefcase-variant-outline',
        },
        {
          title: 'Document management',
          subtitle: 'Upload, download and access documents from anywhere.',
          icon: 'mdi-file-document-outline',
        },
        {
          title: 'Client management',
          subtitle: 'Manage your customers and their relevant information.',
          icon: 'mdi-account-group-outline',
        },
        {
          title: 'Integration and customer management',
          subtitle: 'Receive and share documents and information with your customers effortlessly and secure.',
          icon: 'mdi-account-sync-outline',
        },
        {
          title: 'Powerful search engine',
          subtitle: 'Search for the information you need. Lavvira shows you where it\'s available, regardless of which resource it\'s in.',
          icon: 'mdi-magnify',
        },
        {
          title: 'Multilingual',
          subtitle: 'Lavvira is available in 7 languages: English, French, German, Italian, Spanish, Romanian and Bulgarian. And even more languages will be available soon!',
          icon: 'mdi-earth',
        },
        {
          title: 'In-app notifications',
          subtitle: 'Keep up to date with any changes in your resources',
          icon: 'mdi-bell-ring',
        },
        {
          title: 'Notes',
          subtitle: 'Write down notes so you don\'t miss deadlines and forget important details.',
          icon: 'mdi-notebook',
        },
      ],
      advancedFeatures: [
        {
          title: 'Company and group management',
          subtitle: 'Lavvira is office, company and business ready. Create a private workspace and define access to your resources.',
          icon: 'mdi-account-group-outline',
        },
        {
          title: 'Case models',
          subtitle: 'Turn your cases in to case models and reuse them when needed.',
          icon: 'mdi-briefcase-edit-outline',
        },
        {
          title: 'Collaboration',
          subtitle: 'Co-working on cases, case models and templates based on permissions.',
          icon: 'mdi-account-network-outline',
        },
      ],
    }),
  }
</script>

<style>
.feature-item:hover > .tilt {
    animation: tilt-n-move-shaking 0.5s linear 0.5s;
}
@keyframes tilt-n-move-shaking {
  0% { transform: translate(0, 0) rotate(0deg); }
  25% { transform: translate(5px, 5px) rotate(5deg); }
  50% { transform: translate(0, 0) rotate(0eg); }
  75% { transform: translate(-5px, 5px) rotate(-5deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
}
.feature-item-second:hover > .tilt {
    animation: tilt-n-move-shaking-reverse 0.5s linear 0.5s;
}
@keyframes tilt-n-move-shaking-reverse {
  0% { transform: translate(0, 0) rotate(0deg); }
  25% { transform: translate(-5px, -5px) rotate(-5deg); }
  50% { transform: translate(0, 0) rotate(0eg); }
  75% { transform: translate(-5px, 5px) rotate(5deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
}
</style>
